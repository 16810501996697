﻿h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: inherit;
    font-weight: bold;
    line-height: 1.1;
    color: inherit;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #777777;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 17px;
    margin-bottom: 8.5px;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
    font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 8.5px;
    margin-bottom: 8.5px;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
    font-size: 75%;
}

h1,
.h1 {
    font-size: 31px;
}

h2,
.h2 {
    font-size: 25px;
}

h3,
.h3 {
    font-size: 21px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 12px;
}

h6,
.h6 {
    font-size: 11px;
}

p {
    margin: 0 0 8.5px;
}

.lead {
    margin-bottom: 17px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.4;
}

@media (min-width: 768px) {
    .lead {
        font-size: 18px;
    }
}

small,
.small {
    font-size: 91%;
}

mark,
.mark {
    background-color: #fcf8e3;
    padding: .2em;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;

    .icon-category svg {
        height: 13px;
        width: 12px;

        path {
            fill: #2d6ca2;
        }
    }

    &.active {
        a {
            .icon-category svg path {
                fill: #555;
            }
        }
    }

    a {
        &:hover {
            .icon-category svg path {
                fill: #1d4466;
            }
        }
    }
}

.text-capitalize {
    text-transform: capitalize;
}

.text-muted {
    color: #777777;
}

.text-primary {
    color: #2d6ca2;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #22517a;
}

.text-success {
    color: #3c763d;
}

a.text-success:hover,
a.text-success:focus {
    color: #2b542c;
}

.text-info {
    color: #31708f;
}

a.text-info:hover,
a.text-info:focus {
    color: #245269;
}

.text-warning {
    color: #8a6d3b;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #66512c;
}

.text-danger {
    color: #a94442;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #843534;
}

.bg-primary {
    color: #fff;
    background-color: #2d6ca2;
}

a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #22517a;
}

.bg-success {
    background-color: #dff0d8;
}

a.bg-success:hover,
a.bg-success:focus {
    background-color: #c1e2b3;
}

.bg-info {
    background-color: #d9edf7;
}

a.bg-info:hover,
a.bg-info:focus {
    background-color: #afd9ee;
}

.bg-warning {
    background-color: #fcf8e3;
}

a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #f7ecb5;
}

.bg-danger {
    background-color: #f2dede;
}

a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #e4b9b9;
}

.page-header {
    padding-bottom: 7.5px;
    margin: 34px 0 17px;
    border-bottom: 1px solid #eeeeee;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 8.5px;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
}

.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

dl {
    margin-top: 0;
    margin-bottom: 17px;
}

dt,
dd {
    line-height: 1.42857143;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #777777;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

blockquote {
    padding: 8.5px 17px;
    margin: 0 0 17px;
    font-size: 15px;
    border-left: 5px solid #eeeeee;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
    margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777777;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
    content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eeeeee;
    border-left: 0;
    text-align: right;
}

.blockquote-reverse footer:before,
blockquote.pull-right footer:before,
.blockquote-reverse small:before,
blockquote.pull-right small:before,
.blockquote-reverse .small:before,
blockquote.pull-right .small:before {
    content: '';
}

.blockquote-reverse footer:after,
blockquote.pull-right footer:after,
.blockquote-reverse small:after,
blockquote.pull-right small:after,
.blockquote-reverse .small:after,
blockquote.pull-right .small:after {
    content: '\00A0 \2014';
}

address {
    margin-bottom: 17px;
    font-style: normal;
    line-height: 1.42857143;
}
