﻿span.twitter-typeahead {
  width: 100%;
  z-index: 99;
}

span.twitter-typeahead .tt-dropdown-menu {
  width: 100%;
  border-radius: 5px;
  background-color: white;
}

span.twitter-typeahead .tt-suggestion {
  border-radius: 5px;
  padding: 3px 1px 3px 10px;
}

span.twitter-typeahead .tt-suggestion:hover {
  background-color: #333333;
  cursor: pointer;
  color: white;
}
