﻿.padding-hr-m,
.padding-left-m {
  padding-left: 1rem;
}

.padding-hr-m,
.padding-right-m {
  padding-right: 1rem;
}

.padding-hr-xl,
.padding-left-xl {
  padding-left: 2rem;
}

.padding-left-s {
  padding-left: .5rem;
}

.padding-hr-xl,
.padding-right-xl {
  padding-right: 2rem;
}
