﻿/// <reference path="bootstrap/mixins/clearfix.scss" >
/// <reference path="bootstrap/mixins/variables.scss" >
/// <reference path="bootstrap/mixins/buttons.scss" >

// Variables default Bootstrap
// --------------------------------------------------
$body-bg: #000;

$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: darken($btn-primary-bg, 5%);

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 5%);

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 5%);

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 5%);

// Vertical button groups
// ----------------------

.btn-group-vertical {
  > .btn,
  > .btn-group,
  > .btn-group > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
  }
  // Clear floats so dropdown menus can be properly placed
  > .btn-group {
    @include clearfix;

    > .btn {
      float: none;
    }
  }

  > .btn + .btn,
  > .btn + .btn-group,
  > .btn-group + .btn,
  > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
  }
}

.btn-group-vertical > .btn {
  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  &:first-child:not(:last-child) {
    border-radius: 0;
  }

  &:last-child:not(:first-child) {
    border-radius: 0;
  }
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) {
  > .btn:last-child,
  > .dropdown-toggle {
    border-radius: 0;
  }
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-radius: 0;
}

.btn-group-vertical.three-way-filter {
  display: inline;
}

// Alternate buttons styles inverse and transparent
// --------------------------------------------------
.btn-default {
  &.btn-inverse {
    @include button-variant($btn-default-bg, $btn-default-color, $btn-default-bg);

    &:hover {
      @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    }
  }

  &.btn-transparent {
    @include button-variant($btn-default-bg, transparent, $btn-default-bg);

    &:hover {
      @include button-variant($btn-default-bg, darken($btn-default-bg, 25%), $btn-default-bg);
    }
  }
}

.btn-primary {
  &.btn-inverse {
    @include button-variant($btn-primary-bg, $btn-primary-color, $btn-primary-bg);

    &:hover {
      @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }
  }

  &.btn-transparent {
    @include button-variant($btn-primary-bg, transparent, $btn-primary-bg);

    &:hover {
      @include button-variant($btn-primary-bg, darken($btn-primary-bg, 25%), $btn-primary-bg);
    }
  }
}

.btn-success {
  &.btn-inverse {
    @include button-variant($btn-success-bg, $btn-success-color, $btn-success-bg);

    &:hover {
      @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
    }
  }

  &.btn-transparent {
    @include button-variant($btn-success-bg, transparent, $btn-success-bg);

    &:hover {
      @include button-variant($btn-success-bg, darken($btn-success-bg, 25%), $btn-success-bg);
    }
  }
}

.btn-info {
  &.btn-inverse {
    @include button-variant($btn-info-bg, $btn-info-color, $btn-info-bg);

    &:hover {
      @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
    }
  }

  &.btn-transparent {
    @include button-variant($btn-info-bg, transparent, $btn-info-bg);

    &:hover {
      @include button-variant($btn-info-bg, darken($btn-info-bg, 25%), $btn-info-bg);
    }
  }
}

.btn-warning {
  &.btn-inverse {
    @include button-variant($btn-warning-bg, $btn-warning-color, $btn-warning-bg);

    &:hover {
      @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
    }
  }

  &.btn-transparent {
    @include button-variant($btn-warning-bg, transparent, $btn-warning-bg);

    &:hover {
      @include button-variant($btn-warning-bg, darken($btn-warning-bg, 25%), $btn-warning-bg);
    }
  }
}

.btn-danger {
  &.btn-inverse {
    @include button-variant($btn-danger-bg, $btn-danger-color, $btn-danger-bg);

    &:hover {
      @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
    }
  }

  &.btn-transparent {
    @include button-variant($btn-danger-bg, transparent, $btn-danger-bg);

    &:hover {
      @include button-variant($btn-danger-bg, darken($btn-danger-bg, 25%), $btn-danger-bg);
    }
  }
}