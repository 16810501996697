﻿body {
  min-width: 1500px;
}

a,
tr {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  cursor: pointer;
}

.text-muted {
  color: #777777;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

div[type=popup] {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
  background-color: #555555;
  position: fixed;
  z-index: 1;

  &.active {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

main {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #FFF;
  display: block;
  height: -webkit-calc(100% - 14.1rem);
  height: calc(100% - 14.1rem);
  left: 0;
  overflow: auto;
  position: absolute;
  top: 14.1rem;
  min-width: 1500px;
  width: 100%;
  z-index: 0;
  overflow-y: visible;
}

.box {
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  .box-center {
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
  }

  > .flex {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
  }
}

.clickable {
  cursor: pointer;
}

.collapsible {
  display: none;

  &.active {
    display: block;
  }
}

.color-red-highlight {
  background-color: #fdf7f7;
}

.color-green-highlight {
  background-color: #eaf6ea;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
