#rootBody .mat-dialog-container {
    background-color: #555555;
}

#file-manager-popup-modal {
    background-color: white !important;
}

#truste-consent-track {
    max-width: none !important;
}