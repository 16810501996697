﻿@import '_palette';

.rdo-card-primary-blue {
	background-color: $primary-blue;

	.panel-heading {
		background-color: $primary-blue;
	}
}

.rdo-card-accent-blue {
	background-color: $accent-blue;

	.panel-heading {
		background-color: $accent-blue;
	}
}

.rdo-card-primary-gold {
	background-color: $primary-gold;

	.panel-heading {
		background-color: $primary-gold;
	}
}

.rdo-card-accent-gold {
	background-color: $accent-gold;

	.panel-heading {
		background-color: $accent-gold;
	}
}

.rdo-card-primary-green {
	background-color: $primary-green;

	.panel-heading {
		background-color: $primary-green;
	}
}

.rdo-card-primary-red {
	background-color: $primary-red;

	.panel-heading {
		background-color: $primary-red;
	}
}

.rdo-card-primary-gray {
	background-color: $primary-gray;

	.panel-heading {
		background-color: $primary-gray;
	}
}


.rdo-card-light-gray {
	background-color: #f5f5f5f5;

	.panel-heading {
		background-color: #f5f5f5f5;
	}
}

.rdo-card-light-gray-1 {
	background-color: #dddddd;

	.panel-heading {
		background-color: #dddddd;
	}
}

.rdo-card-primary-teal {
	background-color: $primary-teal;

	.panel-heading {
		background-color: $primary-teal;
	}
}