﻿.chart {
  height: 275px;
}

.chart-title {
  user-select: none;
  cursor: pointer;
}

.filter-month-highlight {
  background-color: #f5f5f5;
  color: #2d6ca2;
}

svg.pie,
.pie svg {
  width: 12px;
  height: 12px;
  margin-top: -3px;
}

svg.pie.lg,
.pie svg.lg {
  margin-bottom: -4px;
  width: 16px;
  height: 16px;
}

svg.pie.lg > path.st0 {
  fill: #777777;
}

span.pie.lg > .svg-pie-icon > path.st0 {
  fill: #777777;
}

span.pie {
  width: 12px;
  height: 12px;
  margin-top: -3px;
}

span.pie.lg > .svg-pie-icon {
  margin-bottom: -4px;
  width: 16px;
  height: 16px;
}

.svg-pie-icon > path.st0 {
  fill: #333333;
}

.svg-pie-icon > path.st1 {
  fill: #FFFFFF;
}

.chart-xaxis-label {
  font-size: 10.5px;
  text-align: center;
  position: absolute;
  top: 27.5px;
  width: 100%;
}

.client-rate-bench-chart {
  position: relative;
  z-index: 50;
  background-color: rgba(218,218,218,0.70);
  padding: 5px;
  font-size: 9pt;
}

.rental-growth-tooltip-chart {
  @extend .client-rate-bench-chart;
}

.client-benchmark-data-label {
  text-align: center;
  color: rgb(0, 0, 0);

  span {
    vertical-align: middle;
  }
}

.chart-inline {
  margin: 0;
  max-height: 0;
  opacity: 0;

  &.active {
    margin: 2rem 0;
    max-height: 200px;
    opacity: 1;
  }
}

.slider-container {
  div.slider-horizontal[style] {
    margin-bottom: 15px !important;
  }

  .slider {
    width: 93%;
    margin-left: 20px;

    @media only screen and (max-width : 992px) {
      width: 97%;
    }

    @media only screen and (max-width : 768px) {
      width: 97%;
    }

    @media only screen and (max-width : 480px) {
      width: 97%;
    }

    @media only screen and (max-width : 320px) {
      width: 97%;
    }
  }

  .slider-tick-label-container {
    .slider-tick-label {
      padding-top: 5px !important;
    }
  }

  .slider-tick {
    opacity: 1;
  }

  .slider-track {
    cursor: default;
  }

  .slider-selection.tick-slider-selection,
  .slider-handle,
  .slider-tick.in-selection {
    background-image: -webkit-linear-gradient(#777777 0%, #777777 100%);
    background-image: -o-linear-gradient(#777777 0%, #777777 100%);
    background-image: linear-gradient(#777777 0%, #777777 100%);
  }

  .slider-selection.tick-slider-selection:hover,
  .slider-handle:hover,
  .slider-tick.in-selection:hover {
    cursor: default;
  }

  .slider-handle.min-slider-handle,
  .slider-handle.max-slider-handle {
    background-image: -webkit-linear-gradient(#c0c0c0 0%, #777777 100%);
    background-image: -o-linear-gradient(#c0c0c0 0%, #777777 100%);
    background-image: linear-gradient(#c0c0c0 0%, #777777 100%);
  }

  .slider-handle.min-slider-handle:hover,
  .slider-handle.max-slider-handle:hover {
    cursor: pointer;
  }
}

.highcharts-container {
  width: 100% !important;
  
  overflow: visible !important;

  // svg {
  //   width: 100% !important;
  // }
}

.rdo-slider-label{
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  vertical-align: top;
  white-space: normal;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
}
.rdo-slider-label-first{
  width: 56%;
  margin-left: calc(44% - 10px);
  text-align: left;
}
.rdo-slider-label-last{
  width: 56%;
  margin-right: calc(44% - 10px);
  text-align: right;
}
.rdo-slider-legend {
  display: flex;
}