
.customTooltip {
    max-width: 50vw !important;
    width: max-content !important;
}

.introjs-overlay {
    z-index: 99999999 !important;
}

.introjs-progressbar {
    background-color: #797979 !important;
}

.introjs-tooltip {
    background-color: #222222e6 !important;
}

.introjs-tooltiptext {
    font-size: 14px !important;
}

.introjs-skipbutton {
    color: white !important;
    user-select: none;
}

.introjs-skipbutton:hover {
    color: rgb(207, 207, 207) !important;
}

.introjs-skipbutton:active {
    color: rgb(131, 131, 131) !important;
}
