
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-none.e-selected::before,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-ascend.e-selected::before,
.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-descend.e-selected::before,
.e-pivotfieldlist-wrapper .e-pv-filtered::before,
.e-pivotfieldlist-container .e-pv-filtered::before {
    color: #245a8c; 
}

#PivotView_PivotFieldList_Wrapper {
    margin: 5px 0px;
    width: calc(100vw - 40px);
    max-width: 1500px;
}

.e-pivotfieldlist-wrapper .e-field-list-container {
    height:400px;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table {
    width: 34%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table {
    width: 66%;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-list-tree-outer-div {
    height: 338px;
    overflow-x: hidden;
    word-wrap: normal;
}

.e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-filters, .e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-left-axis-fields .e-field-list-rows, .e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-columns, .e-pivotfieldlist-wrapper .e-field-list-container .e-axis-table .e-right-axis-fields .e-field-list-values {
    min-height: 195px;
}

.e-axis-content {
    min-height: 165px;
}

#PivotView_PivotFieldList_CalculatedField,
#PivotView_PivotFieldList_DeferUpdateButton1 {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

#PivotView_PivotFieldList_DeferUpdateButton1{
    float: right;
}

#PivotView_PivotFieldList_CalculatedField:hover,
#PivotView_PivotFieldList_DeferUpdateButton1:hover {
    color: #fff;
    background-color: #22517a;
    border-color: #1a3f5e;
}

:root {
    --totals: "main.tabs.custom_grids.toolbar.totals";
    --sub-totals: "main.tabs.custom_grids.toolbar.sub_totals";
    --format: "main.tabs.custom_grids.toolbar.format";
    --save-as: "main.tabs.custom_grids.toolbar.save_as";
    --number-formatting: "main.tabs.custom_grids.toolbar.number_formatting";
    --remove: "Remove";
    --save: "main.tabs.custom_grids.toolbar.save";
    --new: "main.tabs.custom_grids.toolbar.new";
    --configure-grid: "main.tabs.custom_grids.toolbar.configure_grid";
    --rename: "main.tabs.custom_grids.toolbar.rename";
}

#PivotViewgrandtotal_menu > li::after {
    content: var(--totals);
    font-size: 1.4rem;
}
#PivotViewsubtotal_menu > li::after {
    content: var(--sub-totals);
    font-size: 1.4rem;
}
#PivotViewformatting::after {
    content: var(--format);
    font-size: 1.4rem;
}
#PivotViewsaveas::after {
    content: var(--save-as);
    font-size: 1.4rem;
}
#PivotViewnumberFormatting::after {
    content: var(--number-formatting);
    font-size: 1.4rem;
}
#PivotViewremove::after {
    content: var(--remove);
    font-size: 1.4rem;
}
#PivotViewsave::after {
    content: var(--save);
    font-size: 1.4rem;
}
#PivotViewnew::after {
    content: var(--new);
    font-size: 1.4rem;
}
#PivotViewfieldlist::after {
    content: var(--configure-grid);
    font-size: 1.4rem;
}

#PivotViewrename::after {
    content: var(--rename);
    font-size: 1.4rem;
}
.e-export-icon::before {
    content: '\e93a';
    font-family: 'e-icons';
    font-size: 16px;
    vertical-align: bottom;
}
.e-scheduled-export-icon::before {
    content: '\f8f3';
    font-family: 'e-icons';
    font-size: 16px;
    vertical-align: bottom;
}

#PivotViewpivot-toolbar > div > div.e-toolbar-right > div.e-toolbar-item.e-tbtn-align > button,
#PivotViewpivot-toolbar > div > div.e-toolbar-left > div > button {
    margin: 0;
    height: 40px;
}

[id*="_ConfirmDialog"], [id*="-dialog"], [id*="_ErrorDialog"], [id*="Dialog"] {
    max-width: 500px;
    max-height: 500px;
}

[id*="_dialog-content"] {
    min-height: 35px;
}

.e-pivotview .e-pivot-toolbar .e-menu-wrapper ul .e-menu-item, .e-pivotview .e-pivot-toolbar .e-menu-container ul .e-menu-item {
    height: auto !important;
}

.e-dialog .e-dlg-content {
    overflow-y: hidden;
}

.e-editor-search-wrapper-inner {
    margin-left: 5px;
    margin-right: 5px;
}

.e-editor-inner-search-prompt {
    width: 100%; 
    text-align: center;
}

.e-pivot-calc-dialog-div .e-list-parent .e-list-text {
    width: 90% !important;
    min-width: 140px !important;
  }

  .e-pivot-calc-dialog-div {
    width: 37% !important;
  }

  .e-btn-filter.e-remove {
    display: block !important;
    padding-left: 2px !important;
  }

  