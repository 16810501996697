@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "styles/bootstrap/bootstrap.scss";
@import "styles/typeahead.scss";

// Rouse Analytics CSS
@import "styles/functions/index.scss";
@import "styles/_palette.scss";
@import "styles/margins.scss";
@import "styles/paddings.scss";
@import "styles/tabs.scss";
@import "styles/overlay.scss";
@import "styles/charts.scss";
@import "styles/rdo.styles.scss";
@import "styles/_cards.scss";
@import "styles/_popovers.scss";
@import "styles/_tables.scss";

// Other
@import "styles/misc.scss";
@import "styles/print.scss";
@import "styles/bootstrap-overrides.scss";
@import "styles/toastr.scss";

// Material
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

// Syncfusion
@import './styles/syncfusion-theme.scss';
@import './app/pivotcharts/ej2-base/styles/material.css';
@import './app/pivotcharts/ej2-buttons/styles/material.css';
@import './app/pivotcharts/ej2-dropdowns/styles/material.css';
@import './app/pivotcharts/ej2-grids/styles/material.css';
@import './app/pivotcharts/ej2-inputs/styles/material.css';
@import './app/pivotcharts/ej2-lists/styles/material.css';
@import './app/pivotcharts/ej2-navigations/styles/material.css';
@import './app/pivotcharts/ej2-popups/styles/material.css';
@import "./app/pivotcharts/ej2-splitbuttons/styles/material.css";
@import './app/pivotcharts/ej2-angular-pivotview/styles/material.css';
@import './styles/syncfusion-override.scss';
@import './styles/introjs-override.scss';
