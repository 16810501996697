﻿.popover-charts {
	position: fixed !important;
	top: 0;
	left: 0;
	z-index: 1060;
	color: black;
	display: none;
	max-width: 550px;
	min-width: 125px;
	padding: 1px;
	text-align: left;
	white-space: normal;
	background-color: rgba(219,219,216,0.8) !important;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: none;
	/*border: 1px solid rgba(0, 0, 0, .2);*/
	border-radius: 0px !important;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

	/*&.fade.in {
		opacity: 0.8;
	}*/

	&.left {
		margin-left: -10px;

		.arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: #999;
			border-left-color: rgba(219, 219, 216, 0.8);

			&:after {
				right: 1px;
				bottom: -10px;
				content: " ";
				border-right-width: 0;
				border-left-color: rgba(219,219,216,0.8);
			}
		}
	}

	.arrow,
	.arrow:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}

	.arrow {
		border-width: 11px;
	}

	.arrow:after {
		content: "";
		border-width: 10px;
	}
}
