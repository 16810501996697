﻿@media print {
	rdo-header, rdo-navigation, .filter-menu, .rdo-col-card-fixed {
		display: none !important;
	}

	a[href]:after {
		content: none !important;
	}

	main, .scroll {
		overflow: hidden !important;
	}

	.rdo-col-card-offset {
		width: 100% !important;
	}

	.rdo-nav-pills-center {
		display: inline-block;
		text-align: center;
		width: fit-content;
	}

	.rdo-nav-pills-center > li {
		display: inline-block;
	}

	.center {
		text-align: center;
	}

	.highcharts-container {
		margin: 0 auto;
	}

	.rdo-nav-pills {
		li:not(.active) {
			display: none;
		}

		li {
			border: 0 !important;
			border-style: none !important;
			float: left;

			a:not(.active) {
				display: none;
			}

			.not-printable {
				display: none;
			}
		}
	}
}
