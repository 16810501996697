﻿@import '../styles/_palette.scss';

.rdo-col-card-fixed {
  float: left;
  padding: 0 15px;
  width: 350px;
}

.rdo-col-card-offset {
  float: left;
  padding: 0 15px;
  width: calc(100% - 350px);
}

.rdo-col-card-offset h3 {
  margin-top: 0;
}

.rdo-card {
  border: 4px solid;
}

.rdo-top-padded-value {
  padding-top: 3px;
}

.two-lined-ellipsed {
  white-space: normal !important;
  line-height: 1.3em;
  max-height: 2.3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rdo-card .panel-title {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .default-category-icon svg {
    height: 24px;
    width: 24px;

    path {
      fill: #555;
    }
  }

  .default-category-icon-small svg {
    height: 18px;
    width: 18px;
  }
}

.rdo-card-highlight .panel-title {
  .default-category-icon svg {
    path {
      fill: #2d6ca2;
    }
  }
}

.panel-title.panel-sub-title-left {
  overflow: visible;
}

.rdo-card .panel-heading > .panel-title {
  font-weight: bold;
  text-transform: uppercase;
}

.rdo-card .panel-body {
  padding-top: 0;
}

.rdo-card .panel-body .col-xs-6:nth-of-type(1) {
  padding-right: 0;
}

.rdo-card-default {
  border-color: #eeeeee;
}

.rdo-card-default > .panel-heading {
  color: #777777;
  background-color: #fff;
  border-color: #fff;
}

.rdo-card-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #eeeeee;
}

.rdo-card-default > .panel-heading .badge {
  color: #fff;
  background-color: #777777;
}

.rdo-card-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #eeeeee;
}

.rdo-card-default svg.icon-category,
.rdo-card-default .icon-category svg {
  fill: #777777;
}

.rdo-card-highlight {
  border-color: #2d6ca2;
}

.rdo-card-highlight > .panel-heading {
  color: #777777;
  background-color: #fff;
  border-color: #fff;
}

.rdo-card-highlight > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #2d6ca2;
}

.rdo-card-highlight > .panel-heading .badge {
  color: #fff;
  background-color: #777777;
}

.rdo-card-highlight > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #2d6ca2;
}

.rdo-card-highlight .panel-heading > .panel-title {
  color: #2d6ca2;
}

.rdo-card-highlight svg.icon-category,
.rdo-card-highlight .icon-category svg {
  fill: #2d6ca2;
}

.rdo-card-list .rdo-card {
  cursor: pointer;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.rdo-card-list .rdo-card .panel-title {
  opacity: 0;
  filter: alpha(opacity=0);
}

.rdo-card-list .rdo-card .panel-heading > .panel-title {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.rdo-card-list .rdo-card:hover,
.rdo-card-list .rdo-card.active {
  opacity: 1;
  filter: alpha(opacity=100);
}

.rdo-card-list .rdo-card:hover .panel-title,
.rdo-card-list .rdo-card.active .panel-title {
  opacity: 1;
  filter: alpha(opacity=100);
}

.rdo-card-list .rdo-card:hover .panel-heading > .panel-title,
.rdo-card-list .rdo-card.active .panel-heading > .panel-title {
  opacity: 1;
  filter: alpha(opacity=100);
}

.rdo-card-panel-content {
  display: flex;
  justify-content: space-between;
}

.rdo-card-right-group {
  text-align: right;
  max-width: 50%;
}

.rdo-card-metric {
  white-space: nowrap;
}

.rdo-card-metric svg {
  width: 12px;
  height: 12px;
  margin-bottom: -1px;
}

.rdo-card-metric svg path.st0 {
  fill: #777777;
}

.rdo-panel {
  border: 4px solid #eeeeee;
}

.rdo-panel > .panel-heading .panel-title {
  font-size: 15px;
}

.rdo-panel > .panel-heading .panel-title a {
  display: block;
  line-height: 44px;
}

.rdo-panel > .panel-heading .panel-title a:active,
.rdo-panel > .panel-heading .panel-title a:focus,
.rdo-panel > .panel-heading .panel-title a:hover {
  text-decoration: none;
}

.rdo-panel > .panel-heading .well {
  background-color: #fff;
  margin: 0;
}

.rdo-panel > .panel-heading .well .panel-title {
  color: #2d6ca2;
  font-size: 12px;
}

.rdo-panel > .panel-heading .well-sm {
  padding: 4px 9px;
}

.rdo-panel > .panel-body .h3,
.rdo-panel > .panel-body .h4,
.rdo-panel > .panel-body .h6 {
  margin-bottom: 0;
  margin-top: 0;
}

.rdo-panel > .panel-body .h5 {
  color: #777777;
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 0;
  text-transform: uppercase;
}

.rdo-panel > .panel-body .h6 {
  color: #777777;
  font-size: 12px;
}

.rdo-table-tooltip {
  margin-bottom: 0;

  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    text-align: right;
  }
}

.rdo-table-tooltip > thead > tr > th,
.rdo-table-tooltip > tbody > tr > th,
.rdo-table-tooltip > tfoot > tr > th,
.rdo-table-tooltip > thead > tr > td,
.rdo-table-tooltip > tbody > tr > td,
.rdo-table-tooltip > tfoot > tr > td {
  border-top: 0;
}

.rdo-table-dashboard > thead th {
  display: table-cell;
  float: none;
  position: static;
}

.rdo-table-dashboard > thead th:nth-child(1) {
  width: 10%;
}

.rdo-table-dashboard > thead th:nth-child(2) {
  width: 15%;
}

.rdo-table-dashboard > thead th:nth-child(1n + 3) {
  width: 12.5%;
}

.rdo-table-dashboard > thead th:nth-child(1n + 2),
.rdo-table-dashboard > tbody th:nth-child(1n + 2),
.rdo-table-dashboard > thead td:nth-child(1n + 2),
.rdo-table-dashboard > tbody td:nth-child(1n + 2) {
  text-align: right;
}

.rdo-table-pretty {
  background-color: #fff;
  margin-right: -2px;
}

.rdo-table-pretty a:focus,
.rdo-table-pretty a:hover {
  text-decoration: none;
}

.rdo-table-pretty > thead > tr > th {
  height: 52px;
  vertical-align: top;
}

.rdo-table-pretty > tbody > tr > th,
.rdo-table-pretty > tbody > tr > td {
  text-align: right;
}

.rdo-table-pretty .rdo-table-pretty td:nth-child(1) {
  padding-left: 2.1rem;
}

.rdo-expander,
.rdo-expand {
  background-color: #f7f7f7;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rdo-expander .table,
.rdo-expand .table {
  width: auto;
}

.rdo-expander .table > thead th,
.rdo-expand .table > thead th {
  cursor: pointer;
  height: 52px;
  vertical-align: top;
}

.rdo-expander .table > tbody th,
.rdo-expand .table > tbody th,
.rdo-expander .table > tbody td,
.rdo-expand .table > tbody td {
  text-align: right;
}

.rdo-expander {
  margin-right: -3px;
}

.rdo-expander + .rdo-expand {
  margin-right: -2px;
  overflow: hidden;
  width: 0;
}

.rdo-expander + .rdo-expand-1 .table {
  width: 100px;
}

.rdo-expander.active + .rdo-expand-1 {
  width: 100px;
}

.rdo-expander + .rdo-expand-2 .table {
  width: 200px;
}

.rdo-expander.active + .rdo-expand-2 {
  width: 200px;
}

.rdo-expander + .rdo-expand-2-25 .table {
  width: 225px;
}

.rdo-expander.active + .rdo-expand-2-25 {
  width: 225px;
}

.rdo-expander + .rdo-expand-3 .table {
  width: 300px;
}

.rdo-expander.active + .rdo-expand-3 {
  width: 300px;
}

.rdo-expander + .rdo-expand-9 .table {
  width: 900px;
}

.rdo-expander.active + .rdo-expand-9 {
  width: 900px;
}

.rdo-expander + .rdo-expand-12 .table {
  width: 1200px;
}

.rdo-expander.active + .rdo-expand-12 {
  width: 1200px;
}

.rdo-expander-gray {
  background-color: #f5f5f5;
}

.rdo-expander-gray + .rdo-expand {
  background-color: #fdfdfd;
  -webkit-box-shadow: inset -8px 0 6px -6px #eeeeee;
  box-shadow: inset -8px 0 6px -6px #eeeeee;
}

.rdo-expander-gray:hover,
.rdo-expander-gray.active {
  background-color: #f0f0f0;
}

.rdo-expander-row {
  height: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.rdo-inline {
  display: inline-block;
  margin-bottom: 17px;
  vertical-align: top;
}

.rdo-inline table {
  margin-bottom: 0;
}

.table-expanded-row {
  background-color: #ececec;
}

.table-expanded-row-first {
  background-color: #d0d0d0;
}

svg.icon-category,
.icon-category svg {
  height: 40px;
  width: 40px;
  vertical-align: middle;
}

svg.icon-category,
.icon-category svg {
  fill: #333333;
}

.rdo-overlay img {
  user-select: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.loading.icon-category svg {
  -webkit-animation: anim 1s infinite linear;
  animation: anim 1s infinite linear;
}

.grid-border-left {
  border-left: 1px solid #ddd;
  -moz-border-start-width: 2px;
}

@-webkit-keyframes anim {
  from {
    -webkit-transform: rotateY(0deg);
  }

  to {
    -webkit-transform: rotateY(360deg);
  }
}

@keyframes anim {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.chart-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);

  img {
    margin-top: 10%;
    margin-left: 46%;
  }
}

.rdo-nav-pills > li.active > a,
.rdo-nav-pills > li.active > a:hover,
.rdo-nav-pills > li.active > a:focus {
  background-color: #f5f5f5;
  color: #2d6ca2;
  border: none;
  font-weight: bold;
}

.rdo-nav-pills > li > a:hover,
.rdo-nav-pills > li > a:focus {
  background-color: #f5f5f5;
  color: #2d6ca2;
  border: none;
  font-weight: bold;
}

.rdo-nav-pills > li > a.disabled {
  pointer-events: none;
  background-color: transparent;
  color: #a5a5a5;
  border: none;
  font-weight: bold;
}

.rdo-nav-pills > li > a {
  display: inline-block;
}

.panel-default {
  &.no-border {
    border: none;
  }
}

.no-data-available {
  text-align: center;
  font-size: 16px;
  margin-top: 100px;
}

.clickable-row:hover {
  cursor: pointer;
}

.icon-left {
  margin-right: 5px;
}

.icon-right {
  margin-left: 5px;
}

.rdo-tooltip-header {
  text-align: left;
  border: none;
}

.rdo-tooltip-header-title {
  font-weight: bolder;
}

.rdo-tooltip-header-summary {
}

.rdo-tooltip-detail {
  text-align: left;
}

.rdo-tooltip-bold {
  font-weight: bold;
}

.mat-tooltip {
  min-width: 300px !important;
  border: none;
  border-color: #f5f5f5;
  border-width: medium;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #333;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  text-align: left !important;

  & .tooltip-arrow {
    background: white !important;
    margin-bottom: -6px !important;
  }
}

// TODO: This doesn't look like it's used.
.rates-availability-img:before {
  content: url('/assets/images/rates-availability.png');
}

.checklist-leaf-node > label > span.mat-checkbox-label {
  font-weight: normal !important;
}

#filePermissionsDialog {
  font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;

  .mdc-list {
    height: calc(87vh - 240px);
    overflow-y: auto;
  }

  .mdc-label {
    font-size: 12px !important;
    font-weight: 400;
    color: #000000de !important;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  }

  .mat-mdc-list-item-line {
    font-size: 12px !important;
    font-weight: 400;
    color: #000000de !important;
    font-family: 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  }
}

#sched-opts-container {
  .mat-mdc-form-field {
    font-size: 12px !important;
  }

  label {
    padding-top: 3px;
  }

  .mdc-form-field {
    height: 28px !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 46px;
    max-height: 46px;
  }
}
