﻿.margin-hr-m,
.margin-left-m {
  margin-left: 1rem;
}

.margin-bottom-m,
.margin-v-m {
  margin-bottom: 1rem;
}

.margin-bottom-xl,
.margin-v-xl {
  margin-bottom: 2rem;
}

.margin-hr-m,
.margin-left-m {
  margin-left: 1rem;
}

.margin-hr-m,
.margin-right-m {
  margin-right: 1rem;
}

.margin-hr-xl,
.margin-left-xl {
  margin-left: 2rem;
}

.margin-hr-xl,
.margin-right-xl {
  margin-right: 2rem;
}

.margin-top-m,
.margin-v-m {
  margin-top: 1rem;
}

.margin-top-xl,
.margin-v-xl {
  margin-top: 2rem;
}
