﻿$primary-blue: #245A8C;
$accent-blue: #99A8BD;
$primary-gold: #9A7500;
$accent-gold: #B19B63;
$primary-green: #36826A;
$primary-teal: #317B83;
$primary-teal-dark: #00675b;
$primary-red: #A76161;
$primary-red-dark: #ba000d;
$primary-gray: #777;
$active-tab: #f5f5f5;
$font-white: #ffffff;
