﻿.overlay {
  background-color: #FFF;
  height: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 0;
  z-index: 999;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.overlay i {
  color: transparent;
  left: 0;
  font-size: 0;
  position: absolute;
  top: 0;
}

.overlay.active {
  height: 100%;
  opacity: 0.5;
  width: 100%;
}

.overlay.active i {
  color: inherit;
  left: 50%;
  font-size: 5em;
  top: 50%;
}
