﻿/// <reference path="variables.scss" />

.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
  margin-bottom: 17px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 0px;

  > li {
    display: inline-block;

    + li:before {
      //content: "/\00a0";
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $breadcrumb-color;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}

.breadcrumb-from-salesreps,
.breadcrumb-from-customers,
.breadcrumb-from-product-types {
  display: none;
}
